<template>
  <div v-if="foodCode > 1">
    <BadgeOutline class="is-size-7 has-text-weight-semibold with-food" color="green" :textShadow="true" :borderRadius="true" size="small">
      {{ foodCode | foodCode }}
    </BadgeOutline>
      <span
        v-if="!hideDescription"
        class="free is-size-7 has-text-weight-semibold">Price included {{ foodCode | foodCode }}</span>
  </div>
  <div v-else>
    <span v-if="!hideRoomOnly">{{ foodCode | foodCode }}</span>
  </div>
</template>

<script>
import BadgeOutline from './BadgeOutline'

export default {
  name: 'PackagesFoodCode',
  components: {
    [BadgeOutline.name]: BadgeOutline
  },
  props: {
    foodCode: {
      type: String | Number,
      default: 1
    },
    hideRoomOnly: {
      type: Boolean,
      deafult: false
    },
    hideDescription: {
      type: Boolean,
      deafult: false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .free {
    color: $pos-color;
    display: inline-block;
    line-height: 1.38;
  }
  .with-food {
    margin-left: 0 !important;
  }
</style>
