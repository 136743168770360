<template>
  <form class="columns is-gapless kkk is-mobile is-multiline" @submit.prevent="handleSearch">
    <div :class="['column is-12-mobile', locationColCss]">
      <LocationSearch
        style="width: 100%; min-width: 300px;"
        v-model="locationSearchModel" />
      <div class="is-size-7 has-text-danger" v-show="locationError">{{locationError}}</div>
    </div>
    <div class="column is-narrow is-12-mobile"
      style="min-width: 260px;">
      <DateRange v-model="dateRangeModel"
        :layoutMode="$mq.all"
        style="width: 100%;min-width: 270px;"/>
      <div class="is-size-7 has-text-danger" v-show="dateRangeError">{{dateRangeError}}</div>
    </div>
    <div class="column is-narrow-tablet col-guest" style="width: 250px;">
      <SearchGuest v-model="guestModel"
        @opened="preventSearch"
        @closed="enableSearch"/>
    </div>
    <div class="column is-narrow col-action">
      <el-button type="warning"
        :disabled="isSearchDisabled"
        native-type="submit">Search</el-button>
    </div>
  </form>
</template>

<script>
import LocationSearch from './LocationSearch'
import DateRange from './DateRange'
import SearchGuest from './SearchGuest'

const defaultRoomCount = 1
const defaultAdultCount = 2

export default {
  name: 'SearchBarMany',
  components: {
    [LocationSearch.name]: LocationSearch,
    [DateRange.name]: DateRange,
    [SearchGuest.name]: SearchGuest
  },
  props: {
    locationColCss: String,
    queryParams: {
      type: Object,
      default () {
        return { }
      }
    }
  },
  data () {
    return {
      isSearchDisabled: false,
      // Use it later
      // meta: {
      //   placeId: undefined,
      //   queryString: '',
      //   starRatingPopover: false,
      //   pricesPopover: false
      // },
      locationError: '',
      dateRangeError: ''
    }
  },
  computed: {
    guestModel: {
      get () {
        return {
          adultCount: this.queryParams.adultCount || defaultAdultCount,
          roomCount: this.queryParams.roomCount || defaultRoomCount,
          children: this.queryParams.children
        }
      },
      set (val) {
        this.$lv2Store.setQueryParams(val)
      }
    },
    dateRangeModel: {
      get () {
        return {
          checkInDate: this.queryParams.checkInDate,
          checkOutDate: this.queryParams.checkOutDate
        }
      },
      set (val) {
        this.$lv2Store.setQueryParams(val)
      }
    },
    locationSearchModel: {
      get () {
        return {
          locationQuery: this.queryParams.locationQuery,
          regionId: this.queryParams.regionId
        }
      },
      set (val) {
        this.$lv2Store.setQueryParams(val)
      }
    },
    searchManyQuery () {
      return {
        locationQuery: this.locationSearchModel.locationQuery,
        regionName: this.locationSearchModel.locationQuery,
        regionId: this.locationSearchModel.regionId,
        checkInDate: this.dateRangeModel.checkInDate,
        checkOutDate: this.dateRangeModel.checkOutDate,
        roomCount: this.guestModel.roomCount,
        adultCount: this.guestModel.adultCount,
        children: this.guestModel.children || undefined
      }
    }
  },
  activated () {
    window.scrollTo(0, 1)
  },
  watch: {
    'locationSearchModel.regionId': {
      handler (nV) {
        if (nV == null || nV === '') {
          this.locationError = 'Where you want to go?'
          return
        }
        this.locationError = ''
      }
    },
    'dateRangeModel': {
      deep: true,
      handler (nV) {
        this.dateRangeValidator(nV)
      }
    }
  },
  methods: {
    dateRangeValidator (val) {
      if (val == null) {
        this.dateRangeError = 'Check-in date is required'
        return false
      }
      if (val.checkOutDate == null || val.checkOutDate === '') {
        this.dateRangeError = 'Check-out date is required'
        return false
      }
      this.dateRangeError = ''
      return true
    },
    getQuery (more) {
      return {
        ...(this.searchManyQuery),
        currency: this.lv2State.meta.currency,
        searchId: this.$lv2_newSearchId(),
        ...more
      }
    },
    handleSearch () {
      const query = this.getQuery({ page: 1 })
      if (query.regionId == null || query.regionId === '') {
        this.locationError = 'Where you want to go?'
        return
      }
      if (!this.dateRangeValidator({ checkInDate: query.checkInDate, checkOutDate: query.checkOutDate })) return

      this.$emit('search', query)
    },
    preventSearch () {
      this.isSearchDisabled = true
    },
    enableSearch () {
      this.isSearchDisabled = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  // .kkk {
  //   margin-top: $bleed * 2;
  // }
  .kkk.columns > .column {
    padding: 0 $bleed * 2 $bleed  0 !important;
    &:last-child {
      padding-right: 0 !important;
    }
    &:first-child {
      padding-left: 0 !important;
    }
    @include mobile {
      padding-bottom: $bleed * 2 !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      &.col-action {
        padding-left: $bleed * 2 !important;
      }
    }
  }
</style>

<style lang="scss">
  .el-autocomplete-suggestion li {
    padding: 0;
  }
</style>
