<template>
  <div>
    <!-- <div class="is-size-7 has-text-grey">Discount</div> -->
    <div class="discount-badge"
    v-if="adjustmentPct">
    SAVE {{ Math.abs(adjustmentPct) | pct({ factionDigits: 0 }) }} TODAY!
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdjustmentBadge',
  props: {
    adjustmentPct: [Number, undefined]
  }
}
</script>

<style lang="scss" scoped>
  .discount-badge {
    font-weight: 600;
    color: #ed4341;
    text-transform: uppercase;
  }
</style>
