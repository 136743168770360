<template>
  <div class="property-preview">
    <div
      v-if="property"
      class=""
      style="position: absolute; left:0; padding: 12px;max-width: 90%; overflow: hidden;z-index: 3000;">
      <h4 class="has-text-weight-semibold subtitle is-6 has-text-white is-marginless">
      {{ property.name }} <StarRatings class="prop-star is-size-7 is-inline-block" :ratings="property.starRating"
      style="vertical-align: middle;"/>
      </h4>
      <div
        v-if="packages && packages[0]"
        class="list-action has-text-white is-inline-block">
        <div class="is-6 is-marginless">
          <span class="is-size-7 has-text-white-bis">from</span>&nbsp;<span class="has-text-weight-semibold ">{{ packages[0].unitAdjustedDisplayRate | currencyObj({ factionDigits: 0 }) }}</span>
        </div>
      </div>
    </div>
    <a class="has-text-white" style="position: absolute; right:0; padding: 12px;z-index: 3000;"
      @click="handleClosed">
      <i class="el-icon-close is-size-3"/>
    </a>
    <SwiperContainer
      :swiperConfig="swiperConfig"
      ref="swiper"
      v-model="swiperProps">
      <template slot-scope="{ swiper }">
        <SwiperSlides
          class="property-preview--slides"
          :swiper="swiper"
          v-for="(it, idx) in maxGallery"
          :key="it.s.fileName"
          >
            <img
              class="property-preview--img"
              v-lazy="{ src: it.m.url, error: it.s.url, loading: it.s.url, attempt: 0 }"
            />
        </SwiperSlides>

        <SwiperSlides
          v-if="reviews && reviews.summary"
          class="property-preview--slides reviews-slide"
          :swiper="swiper"
          :idx="99"
          >
          <div class="property-preview--img" style="height: auto;">
          <div class="property-preview--box property-preview--reviews is-clearfix"
            @click.capture.stop="{}">
            <TrustYouRating size="s" v-bind="property.trustYou"/>
            <PpGap size="large" />
            <p class="has-text-dark subtitle is-6">{{reviews.summary && reviews.summary.text }}</p>
            <TrustYouHotelType
              v-if="reviews.hotelTypeList"
              class="subtitle is-7"
              v-for="it in reviews.hotelTypeList"
              :key="it.categoryId"
              v-bind="it"
              />
            <PpGap size="large" />
            <div class="">
            <TrustYouRecommendation class="is-size-7" :reviewsDistribution="reviews.summary && reviews.summary.reviewsDistribution" />
            </div>
            <TrustYouBadgeWrapper
              class="is-pulled-right"
              :badgeList="reviews.badgeList">
              <template slot-scope="{ ranking }">
                <TrustYouRanked class="is-size-7" v-bind="ranking.badgeData" layout="vertical"/>
              </template>
            </TrustYouBadgeWrapper>
            <PpGap />
            <TrustYouPopularWith
              :noMargin="true"
              class="is-size-7"
              v-bind="reviews.summary && reviews.summary.popularWith" />
          </div>
          <router-link
            class="is-size-7 is-block has-text-centered has-text-white"
            style="margin-bottom:0; padding: 12px;"
            replace
            :to="viewMoreLink">
              Read more reviews
          </router-link>
          </div>
        </SwiperSlides>

        <SwiperSlides
          v-if="!isLoading"
          class="property-preview--slides"
          :swiper="swiper"
          :idx="100"
          >
          <div class="property-preview--img" style="height: auto;">
          <div class="property-preview--box property-preview--packages is-clearfix"
            v-for="it in maxPackages"
            :key="it.token">
            <div
              class="list-action is-size-6 has-text-black has-text-right is-pulled-right">
                <div class="has-text-line-through subtitle is-7 has-text-grey is-marginless"
                  v-if="it.initialAdjustmentPct < -0.00">
                  {{ it.unitDisplayRate | currencyObj({ factionDigits: 0 }) }}
                </div>
                <div class="is-6 is-marginless subtitle has-text-weight-semibold has-text-dark">{{ it.unitAdjustedDisplayRate | currencyObj({ factionDigits: 0 }) }}</div>
                <div class="is-size-7 has-text-grey">Nightly avg.</div>
            </div>
            <h6 class="has-text-dark prop-name has-text-weight-semibold subtitle is-7 is-marginless is-uppercase">
              {{ it.supplierDescription || it.description }}
            </h6>
            <PackagesFoodCode class="is-size-7" :foodCode="it.foodCode" :hideDescription="true"/>
            <PpGap size="small" />
            <AdjustmentBadge
            class="is-size-7"
            v-if="(it.initialAdjustmentPct < -0.00)"
            :adjustmentPct="it.initialAdjustmentPct" />
          </div>
            <router-link
              class="is-size-7 is-block has-text-centered  has-text-white"
              style="margin-bottom:0; padding: 12px;"
              replace
              :to="Object.assign({}, viewMoreLink, { hash: '#pckgslist' })">
                Show more room options
            </router-link>
          </div>
        </SwiperSlides>
      </template>
    </SwiperContainer>
    <div class="property-preview--footer"
      @click.stop="{}"
      v-if="property">
      <transition name="view-slide-right" mode="in-out">
        <el-button
          v-if="reviews && reviews.summary"
          size="small"
          type="primary"
          @click="handleSlideTo('.reviews-slide')">
          Reviews
        </el-button>
      </transition>
        <el-button
          size="small"
          :disabled="isLoading"
          :loading="isLoading"
          type="primary"
          @click="handleSlideTo(0)">
          Rooms
        </el-button>
      <router-link
        tag="button"
        class="el-button el-button--warning el-button--small is-pulled-right has-text-weight-semibold"
        style="margin-left: 16px;"
        :to="viewMoreLink"
        replace
        >More details <i class="el-icon-arrow-right" />
      </router-link>
      <!-- <router-link
        class="is-italic property--location is-size-7"
        :to="{
          name: 'searchMap', query: Object.assign({}, lv2State.queryParams), params:  lv2State.queryParams, hash: '#pc' + property.propertyCode }"
        >
        (view in map)
      </router-link> -->
    </div>
  </div>
</template>

<script>
import BadgeOutline from '@/components/BadgeOutline'
import AdjustmentBadge from './AdjustmentBadge'
import PackagesFoodCode from './PackagesFoodCode'
import TrustYouRating from '@/components/TrustYouRating'
import TrustYouRanked from '@/components/TrustYouRanked'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import TrustYouBadgeWrapper from '@/components/TrustYouBadgeWrapper'
import StarRatings from '@/components/StarRatings'

export default {
  name: 'PropertyPreview',
  components: {
    [StarRatings.name]: StarRatings,
    [BadgeOutline.name]: BadgeOutline,
    [PackagesFoodCode.name]: PackagesFoodCode,
    [AdjustmentBadge.name]: AdjustmentBadge,
    [TrustYouRating.name]: TrustYouRating,
    [TrustYouPopularWith.name]: TrustYouPopularWith,
    [TrustYouRanked.name]: TrustYouRanked,
    [TrustYouHotelType.name]: TrustYouHotelType,
    [TrustYouRecommendation.name]: TrustYouRecommendation,
    [TrustYouBadgeWrapper.name]: TrustYouBadgeWrapper,
    'SwiperContainer': () => import(/* webpackChunkName: "mobiledefer" */'./swiper/SwiperContainer'),
    'SwiperSlides': () => import(/* webpackChunkName: "mobiledefer" */'./swiper/SwiperSlides')
  },
  props: {
    property: {
      type: Object,
      default () {
        return {}
      }
    },
    reviews: {
      type: Object,
      default () {
        return {}
      }
    },
    packages: {
      type: Array,
      default () {
        return []
      }
    },
    continuePolling: Boolean,
    isSearching: Boolean,
    isLoading: Boolean
  },
  data () {
    return {
      swiperProps: {},
      initialSlide: 0,
      swiperConfig: {
        slidesPerView: 'auto',
        spaceBetween: 12,
        centeredSlides: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false
        }
      },
      routeState: undefined
    }
  },
  computed: {
    viewMoreLink () {
      return {
        name: 'property',
        params: {
          name: this.property.name
        },
        query: Object.assign({}, this.$route.query, { propertyCode: this.property.propertyCode, locationQuery: this.$route.params.locationQuery })
      }
    },
    maxGallery () {
      return this.property.gallery && this.property.gallery.slice(0, 11)
    },
    maxPackages () {
      return this.packages.slice(0, 4)
    }
  },
  mounted () {
    this.$Lazyload.$on('loaded', this.handleImageLoaded)
    // window.addEventListener('popstate', this.handlePopState)
    this.routeState = history.state
    // history.pushState({ propertyPreviewVisibility: true, key: Date.now() }, 'Open property preview', '')
  },
  beforeDestroy () {
    this.$Lazyload.$off('loaded', this.handleImageLoaded)
    // win      dow.removeEventListener('popstate', this.handlePopState)
  },
  methods: {
    handleClosed () {
      history.back()
    },
    handlePopState (e) {
      if (e.state.key === this.routeState.key) this.$emit('close')
    },
    // Listen to the image lazy load. and update swiper gallery
    // { bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error }
    handleImageLoaded ({ el }) {
      if (el.classList.contains('property-preview--img')) {
        this.$refs.swiper.swiper.update()
      }
    },
    handleSlideTo (val) {
      if (this.$refs.swiper) {
        // if is a number means is a idx
        const s = this.$refs.swiper.swiper.slides
        if (!isNaN(val)) {
          this.$refs.swiper.swiper.slideTo(s.length + val - 1)
        } else {
          const idx = Array.from(s).findIndex((el) => el.classList.contains('reviews-slide'))
          this.$refs.swiper.swiper.slideTo(idx)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .property-preview--slides {
    height: 76vh;
    margin-top: 68px;
    width: calc(100vw - 24px);
    position: relative;
  }
  .property-preview--img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    object-fit: contain;
  }
  .property-preview--box {
    padding: $bleed * 2;
    background-color: $white;
    border-radius: $bleed /2;
  }
  .property-preview--reviews,
  .property-preview--packages {
    margin-bottom: $bleed * 2;
  }
  .property-preview--footer {
    left: 0;
    right: 0;
    margin: $bleed * 2;
    position: fixed;
    bottom: $bleed * 4;
    z-index: 9;
    line-height: 1;
  }
</style>
