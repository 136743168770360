<template>
  <div class="swiper-slide"
    ref="slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SwiperSlides',
  props: {
    swiper: {
      type: Object,
      required: true
    },
    idx: {
      type: Number
    }
  },
  data () {
    return {
      localIdx: 0
    }
  },
  mounted () {
    if (this.idx != null) {
      this.swiper.addSlide(this.idx, this.$refs.slide)
      // this.swiper.update()
      this.localIdx = this.idx
    } else {
      this.swiper.appendSlide(this.$refs.slide)
      this.swiper.update()
      this.localIdx = this.idx
    }
  },
  beforeDestroy () {
    if (!this.swiper.destroyed) {
      this.swiper.removeSlide(this.localIdx)
      this.swiper.update()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .swiper-slide {
    // background-color: crimson;
    // width: 180px;
    // padding: $bleed;
    line-height: initial;
    // text-align: center;
    // font-size: 18px;
    /* Center slide text vertically */
    // display: flex;
    // justify-content: center;
    // align-items: center;
    flex-shrink: 0;
    // width: 100%;
    // height: 100%;
    // position: relative;
    // transition-property: transform;
    // flex-direction: column;
  }
</style>
