<template>
  <div class="swiper-container"
    ref="swiperContainer">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <template v-if="Boolean(this.swiper)">
        <slot
          :swiper="swiper">
        </slot>
      </template>
        <!-- <div class="swiper-slide">Slide 1</div>
        <div class="swiper-slide">Slide 2</div>
        <div class="swiper-slide">Slide 3</div> -->
    </div>
    <!-- If we need pagination -->
    <!-- <div class="swiper-pagination"></div> -->

    <!-- If we need navigation buttons -->
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div> -->

    <!-- If we need scrollbar -->
    <!-- <div class="swiper-scrollbar"></div> -->
  </div>
</template>

<script>
// import { Swiper } from 'swiper/dist/js/swiper.esm.js'
import Swiper from 'swiper'

export default {
  name: 'SwiperContainer',
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    swiperConfig: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      debug: process.env.NODE_ENV === 'development',
      swiper: null
    }
  },
  computed: {
    mergeConfig () {
      return {
        init: false,
        ...this.swiperConfig
      }
    }
  },
  mounted () {
    this.swiper = new Swiper(this.$refs.swiperContainer, this.mergeConfig)
    this.swiper.on('slideChange', this.slideChange)
    this.swiper.on('sliderMove', this.sliderMove)
    this.swiper.on('init', this.init)
    this.swiper.on('beforeDestroy', this.swiperBeforeDestroy)
    this.swiper.init()
  },
  activated () {
    this.swiper.update()
  },
  beforeDestroy () {
    if (this.debug) console.log('%c swiper destory ', 'background-color: blue; color: #fff;')
    this.swiper.destroy()
  },
  methods: {
    getSwiperProperties () {
      if (this.swiper == null) return {}
      return {
        activeIndex: this.swiper.activeIndex,
        realIndex: this.swiper.realIndex,
        previousIndex: this.swiper.previousIndex,
        clickedIndex: this.swiper.clickedIndex
      }
    },
    init () {
      if (this.debug) console.log('%c swiper init ', 'background-color: blue; color: #fff;')
      this.$emit('input', this.getSwiperProperties())
      this.$emit('init')
    },
    slideChange () {
      if (this.debug) console.log('%c swiper slideChange ', 'background-color: blue; color: #fff;')
      this.$emit('input', this.getSwiperProperties())
    },
    swiperSlideTo ({ slideIdx } = {}) {
      if (this.debug) console.log('%c swiper swiperSlideTo ', slideIdx, 'background-color: blue; color: #fff;')
      this.swiper.slideTo(slideIdx)
    },
    swiperBeforeDestroy () {
      this.swiper.detachEvents()
      this.swiper = null
    },
    sliderMove (evt) {
      // evt.stopPropagation()
      // evt.stopImmediatePropagation()
      // evt.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-container {
    width: 100%;
    height: 100%;
  }
</style>
