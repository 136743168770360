<template>
  <div class="search-bar-mobile--pop"
    @click.stop.prevent="{}">
    <PpSwipe style="width: 100%; height: 100%;"
      @outOfBound="historyBack">
    <PropertyPreview
      :isSearching="lv2State.searchOne.isSearching"
      :continuePolling="lv2State.searchOne.continuePolling"
      :property="lv2State.searchOne.property"
      :reviews="lv2State.searchOne.reviews"
      :isLoading="mx_searchOne_isLoading"
      :packages="lv2State.searchOne.availability && lv2State.searchOne.availability.packages"
      @close="handlePreviewOutOfBound"
      />
    </PpSwipe>
  </div>
</template>

<script>
import searchOneMix from '../mixins/searchOne'

export default {
  name: 'PtyPreview',
  mixins: [ searchOneMix ],
  components: {
    'PropertyPreview': () => import(/* webpackChunkName: "mobiledefer" */ '../components/PropertyPreview')
  },
  props: {
    params: Object,
    query: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      /** overwrite mixin data */
      fetchInterval: 777 // ms
    }
  },
  created () {
    this.mx_searchOne_cancelFetch()
    this.mx_searchOne_fetch()
    // this.$lv2Store.clearSearchOne()
  },
  beforeDestroy () {
    this.mx_searchOne_cancelFetch()
  },
  methods: {
    historyBack () {
      history.back()
    },
    handlePreviewOutOfBound () {
      // factories.source.one.cancel('CLOSE_PREVIEW')
    },
    handleFetchCompleted () {
    }
  },
  metaInfo () {
    return {
      title: `Preview: ${this.query.name} – ${this.params.locationQuery || this.query.locationQuery}`,
      titleTemplate: (title) => title
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
</style>
